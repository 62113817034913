
import { useAPI } from "@/use";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Auth",
  props: {
    accessCode: {
      type: String,
      required: true,
    }
  },
  async created() {
    const request = await useAPI().common.auth.loginAccessCodeMethod(this.accessCode);
    this.$store.dispatch("onLogin", request.data);
    this.$router.push('/');
  }
});
